import request, { exportRequest } from '@/utils/request';
import type { FetchComboOrderResp, ComboOrder } from '@/pages/ComboOrder/combo-order.data';

export async function createComboOrder(params: any): Promise<ComboOrder> {
  return request.post(`/cms/combo-orders`, {
    data: params,
  });
}

export async function fetchComboOrders(params?: any): Promise<FetchComboOrderResp> {
  return request.get('/cms/combo-orders', {
    params,
  });
}

export async function updateComboOrder(id: string, params?: any): Promise<any> {
  return request.patch(`/cms/combo-orders/${id}`, {
    data: params,
  });
}

export async function getDetailComboOrder(id: string): Promise<ComboOrder> {
  return request.get(`/cms/combo-orders/${id}`);
}

export async function deleteComboOrder(id: string): Promise<any> {
  return request.delete(`/cms/combo-orders/${id}`);
}

export async function exportPdf(id: string) {
  return exportRequest.get(`/combo-orders/export/${id}`);
}

export async function getSimCountries() {
  return request.get(`/cms/sim4g/countries`);
}

export async function getSims() {
  return request.get(`/cms/sim4g/sims`);
}
