export default {
  yes: 'Có',
  no: 'Không',
  created_at: 'Ngày tạo',
  updated_at: 'Ngày cập nhật',
  cancel: 'Hủy',
  upload: 'Tải lên',
  update: 'Cập nhật',
  delete: 'Xóa',
  adult: 'Người lớn',
  child: 'Trẻ em',
  baby: 'Sơ sinh',
  actions: 'Hành động',
  // activity history
  'activity.user_login': 'đã truy cập gần đây nhất vào lúc',
  'activity.tour_create': 'đã tạo Tour',
  'activity.tour_update': 'đã chỉnh sửa Tour',
  'activity.tour_delete': 'đã xoá Tour',
  'activity.tour_export': 'đã xuất Tour',
  'activity.quotation_create': 'chỉnh sửa',
  'activity.quotation_update': 'chỉnh sửa',
  'activity.tour_sell_create': 'đã mở bán',
  'activity.tour_sell_update': 'đã cập nhật',
  'activity.tour_order_create': 'đã tạo đơn hàng',
  'activity.tour_order_update': 'đã cập nhật lại thông tin đơn hàng',
  'activity.tour_order_delete': 'đã xoá đơn hàng',
  'activity.tour_order_update_status': 'đã cập nhật trạng thái',
  'activity.supplier_create': 'đã thêm mới nhà cung cấp',
  'activity.supplier_update': 'đã chỉnh sửa thông tin nhà cung cấp',
  'activity.supplier_delete': 'đã xoá nhà cung cấp',
  'activity.customer_create': 'đã thêm mới khách hàng',
  'activity.customer_update': 'đã chỉnh sửa thông tin khách hàng',
  'activity.customer_delete': 'đã xoá khách hàng',
  'activity.cost_create': 'đã thêm mới chi phí',
  'activity.cost_update': 'đã chỉnh sửa chi phí',
  'activity.cost_delete': 'đã xoá chi phí',
  'activity.cost_delete_stats': 'đã cập nhật trạng thái thanh toán của chi phí',
  'activity.cost_category_create': 'chỉnh sửa',
  'activity.cost_category_update': 'chỉnh sửa',
  'activity.cost_category_delete': 'chỉnh sửa',
  // booking
  'booking.search_placeholder':
    'Tìm kiếm theo bookingId, tên khách hàng, email, số điện thoại, điểm đến,...',
  'booking.add_booking': 'Tạo yêu cầu mới',
  'booking.add_quote': 'Thêm báo giá',
  'booking.cancel_booking': 'Hủy Booking',
  'booking.book_id': 'Booking ID',
  'booking.trip_destination': 'Trip/Điểm đến',
  'booking.total_passenger': 'Số lượng người',
  'booking.customer': 'Khách hàng',
  'booking.status': 'Trạng thái',
  'booking.phone': 'Số điện thoại',
  'booking.serviceType': 'Loại yêu cầu',
  'booking.duration': 'Số ngày',
  'booking.departure_date': 'Ngày khởi hành',
  'booking.departure_area': 'Điểm khởi hành',
  'booking.destination_area': 'Điểm khởi hành',
  'booking.created_at': 'Ngày tạo',
  'booking.trip_info': 'Thông tin chuyến đi',
  'booking.service_request': 'Yêu cầu về dịch vụ',
  'booking.number_of_people': 'Số lượng người',
  'booking.adults': 'Người lớn (Trên 12 tuổi): {quantity} người',
  'booking.children': 'Trẻ em (Từ 1-12 tuổi): {quantity} người',
  'booking.babies': 'Sơ sinh (Từ 0-2 tuổi): {quantity} người',
  'booking.travel_type': 'Loại hình du lịch',

  'booking.trip_type_personal': 'Riêng tư',
  'booking.trip_type_shared': 'Chia sẻ',
  'booking.trip_type_building': 'Building',
  'booking.trip_type_thien_nhien': 'Thiên nhiên',
  'booking.trip_type_tam_linh': 'Tâm linh',
  'booking.trip_type_nghi_duong': 'Nghỉ dưỡng',
  'booking.trip_type_lich_su_van_hoa': 'Lịch sử & văn hóa',
  'booking.trip_type_mao_hiem': 'Mạo hiểm',
  'booking.trip_type_trai_nghiem': 'Trải nghiệm',
  'booking.trip_type_am_thuc': 'Ẩm thực',
  'booking.trip_type_tinh_yeu': 'Tình yêu',

  'booking.include_hotel': 'Khách sạn/Nơi cư trú',
  'booking.include_moving': 'Phương tiện di chuyển',
  'booking.include_ticket': 'Vé thăm quan',
  'booking.include_food': 'Dịch vụ ăn uống',
  'booking.request_pickup': 'Đưa đón tại sân bay',
  'booking.provide_4g': 'Cung cấp sim 4G',
  'booking.total_cost': 'Tổng chi tiêu dự kiến',
  'booking.request_author': 'Người yêu cầu báo giá',
  'booking.quote_receiver': 'Tên người nhận báo giá',
  'booking.email': 'Email',
  'booking.time_receive_call': 'Thời gian có thể tiếp nhận cuộc gọi',
  'booking.notes': 'Ghi chú',
  'booking_status.pending': 'Chờ xác thực',
  'booking_status.verified': 'Đã xác thực',
  'booking_status.quoted': 'Đã có báo giá',
  'booking_status.rejected': 'Đã hủy',
  'booking_status.canceled': 'Đã hủy',
  'booking_status.paid': 'Đã thanh toán',
  'booking_status.partial': 'Đã thanh toán một phần',
  'booking_status.booking': 'Đã xác thực',
  'booking_status.quotationAccepted': 'Đã chấp nhận báo giá',
  'booking_status.waitingForApproveFirstPayment': 'Đợi duyệt thanh toán',
  'booking_status.invoiceSent': 'Đã gửi hóa đơn',
  'booking_status.partialPaid': 'Đã thanh toán một phần',
  'booking_status.completed': 'Đã hoàn thành',
  // partner
  'partner.add_partner': 'Thêm đối tác',
  'partner.add_policy': 'Thêm chính sách',
  'partner.id': 'Mã đối tác',
  'partner.name': 'Tên đối tác',
  'partner.owner': 'Tài khoản quản lý',
  'partner.address': 'Địa chỉ',
  'partner.phone': 'Số điện thoại',
  'partner.email': 'Email',
  'partner.package': 'Gói',
  'partner.expirePackageDate': 'Hạn sử dụng',
  'partner.taxCode': 'Mã số thuế',
  'partner.status': 'Trạng Thái',
  'partner.bankName': 'Ngân hàng',
  'partner.bankBranch': 'Chi nhánh',
  'partner.accountHolder': 'Chủ tài khoản',
  'partner.accountNumber': 'Số tài khoản',
  'partner.password': 'Mật khẩu',
  'partner.rePassword': 'Nhập lại mật khẩu',
  'partner.qrCode': 'QR chuyển khoản',
  'partner.quote_quantity': 'Số lượng báo giá',
  'partner.booking_quantity': 'Số lượng booking',
  'partner.logo': 'Logo Đối tác',
  'partner.search_placeholder': 'Tìm kiếm theo Tên đối tác, Email, Số điện thoại',
  'partner.info': 'Thông tin đối tác',
  'partner.policy': 'Chính sách',
  'partner.quotes_list': 'Danh sách báo giá',
  'partner_status.pending': 'Đang chờ duyệt',
  'partner_status.active': 'Đã duyệt',
  'partner_status.inactive': 'Tạm khóa',
  'partner_status.rejected': 'Từ chối',
  // policy
  'policy.add': 'Thêm chính sách',
  'policy.id': 'Mã chính sách',
  'policy.name': 'Tên chính sách',
  'policy.createdAt': 'Ngày tạo',
  'policy.servicesInclude': 'Bao gồm',
  'policy.servicesInclude_placeholder': 'Nhập vào các dịch vụ bao gồm',
  'policy.servicesExclude': 'Loại trừ',
  'policy.servicesExclude_placeholder': 'Nhập các dịch vụ loại trừ',
  'policy.children': 'Chính sách trẻ em',
  'policy.children_placeholder': 'Nhập chính sách trẻ em',
  'policy.refund': 'Chính sách hoàn hủy',
  'policy.refund_placeholder': 'Nhập chính sách hoàn hủy',
  'policy.foreigner': 'Chính sách cho khách quốc tế',
  'policy.foreigner_placeholder': 'Nhập chính sách cho khách quốc tế',
  'policy.moreInfo': 'Thông tin thêm',
  'policy.moreInfo_placeholder': 'Nhập vào thông tin thêm',
  // menu
  'menu.add': 'Thêm menu',
  'menu.id': 'Mã menu',
  'menu.name': 'Tên menu',
  'menu.search_placeholder': 'Tìm kiếm',
  'menu.name_placeholder': 'vd: Menu 1 - Nhà hàng Phương Nam',
  'menu.content': 'Nội dung',
  'menu.content_placeholder': 'Nhập nội dung...',
  'menu.restaurant': 'Nhà hàng',
  'menu.restaurant_placeholder': 'Vd: Nhà Hàng Phương Nam - 01 Châu Thị Vĩnh Tế, tp Đà Nẵng',
  'menu.createdAt': 'Ngày tạo',
  // partner-tour
  'tour.hotelType_twoStars': '2 sao',
  'tour.hotelType_threeStars': '3 sao',
  'tour.hotelType_fourStars': '4 sao',
  'tour.hotelType_fiveStars': '5 sao',
  'tour.hotelType_sixStars': '6 sao',
  'tour.hotelType_homestay': 'Homestay',
  'tour.hotelType_resort': 'Resort',
  'tour.hotelType_villa': 'Villa',
  'tour.hotelType_noRequest': 'Không yêu cầu',
  // cost
  'cost.select_status': 'Chọn trạng thái',
  'cost.add': 'Thêm chi phí',
  'cost.add_payment': 'Thêm thanh toán',
  'cost.update_payment': 'Cập nhật thanh toán',
  'cost.update': 'Cập nhật',
  'cost.tour': 'Tour',
  'cost.departureArea': 'Khởi hành từ',
  'cost.departureDate': 'Ngày khởi hành',
  'cost.id': 'Mã chi phí',
  'cost.cost': 'Tổng chi phí',
  'cost.status': 'Trạng thái',
  'cost.status_created': 'Tạo mới',
  'cost.status_success': 'Đã thanh toán',
  'cost.status_refund': 'Hoàn tiền',
  'cost.status_expired': 'Quá hạn',
  'cost.status_deposited': 'Đã đặt cọc',
  'cost.supplier': 'Nhà cung cấp',
  'cost.createdBy': 'Người tạo',
  'cost.createdAt': 'Ngày tạo',
  'cost.costCategory': 'Loại chi phí',
  'cost.costCategories': 'Dịch vụ cung cấp',
  'cost.paymentPeriod': 'Hạn thanh toán',
  'cost.discount': 'Khoản giảm trừ',
  'cost.paid': 'Đã thanh toán',
  'cost.remaining': 'Còn lại',
  'cost.note': 'Ghi chú',
  'cost.departure_date': 'Ngày khởi hành',
  // cost category
  'cost_category.select': 'Chọn loại chi phí',
  'cost_category.add': 'Thêm mới',
  'cost_category.update': 'Cập nhật',
  'cost_category.name': 'Loại chi phí',
  'cost_category.cat_type': 'Phân loại chi phí',
  'cost_category.type_hotel': 'Khách sạn',
  'cost_category.type_restaurant': 'Nhà hàng',
  'cost_category.type_vehicle': 'Phương tiện',
  'cost_category.type_other': 'Khác',
  // supplier
  'supplier.select': 'Chọn nhà cung cấp',
  'supplier.add': 'Thêm mới',
  'supplier.update': 'Cập nhật',
  'supplier.hotelType': 'Số sao',
  'supplier.name': 'Tên',
  'supplier.area': 'Tỉnh/Thành phố',
  'supplier.name_other': 'Tên',
  'supplier.name_airlineTicket': 'Tên',
  'supplier.name_visitTicket': 'Tên',
  'supplier.name_landTour': 'Tên',
  'supplier.name_visa': 'Tên',
  'supplier.name_tourGuild': 'Tên',
  'supplier.name_cruise': 'Tên',
  'supplier.name_boat': 'Tên',
  'supplier.name_train': 'Tên',
  'supplier.name_bus': 'Tên',
  'supplier.name_others': 'Tên',
  'supplier.name_restaurant': 'Tên nhà hàng',
  'supplier.name_vehicle': 'Tên',
  'supplier.name_hotel': 'Khách sạn',
  'supplier.name_resort': 'Resort',
  'supplier.status_active': 'Hoạt động',
  'supplier.status_inactive': 'Ngừng hoạt động',
  'supplier.rooms': 'Số phòng',
  'supplier.cruise_rooms': 'Số Cabin',
  'supplier.address': 'Địa chỉ',
  'supplier.phone': 'Số điện thoại',
  'supplier.email': 'Email',
  'supplier.website': 'Website',
  'supplier.note': 'Ghi chú',
  // 'supplier.': '',
  // customer
  'customer.add': 'Thêm mới',
  'customer.update': 'Cập nhật',
  'customer.name': 'Họ và tên',
  'customer.gender': 'Giới tính',
  'customer.gender_male': 'Nam',
  'customer.gender_female': 'Nữ',
  'customer.address': 'Địa chỉ',
  'customer.country': 'Quốc gia',
  'customer.province': 'Tỉnh/Thành phố',
  'customer.priority': 'Mức ưu tiên',
  'customer.priority_high': 'Cao',
  'customer.priority_medium': 'Trung bình',
  'customer.priority_low': 'Thấp',
  'customer.customerType': 'Loại khách hàng',
  'customer.customerType_vip': 'Vip',
  'customer.customerType_potential': 'Tiềm năng',
  'customer.customerType_merchant': 'Đầu mối',
  'customer.customerType_normal': 'Bình thường',
  'customer.phone': 'Số điện thoại',
  'customer.email': 'Email',
  'customer.birthday': 'Ngày sinh',
  'customer.cccd': 'Số CCCD',
  'customer.publicDate': 'Ngày cấp',
  'customer.publicArea': 'Nơi cấp',
  'customer.passport': 'Số hộ chiếu',
  'customer.passportExpiryDate': 'Hạn hộ chiếu',
  'customer.bankName': 'Ngân hàng',
  'customer.accountNumber': 'Số tài khoản',
  'customer.accountHolder': 'Chủ tài khoản',
  'customer.bankBranch': 'Chi nhánh',
  'customer.swiftCode': 'Swift code',
  // 'customer.': '',
  // order
  'order.add': 'Thêm hóa đơn',
  'order.tour': 'Dịch vụ',
  'order.id': 'Mã hóa đơn',
  'order.quantity': 'Số lượng',
  'order.total_price': 'Tổng thu',
  'order.status': 'Trạng thái',
  'order.status_created': 'Tạo mới',
  'order.status_hold': 'Giữ chỗ',
  'order.status_transferred': 'Đã chuyển khoản',
  'order.status_deposited': 'Đã đặt cọc',
  'order.status_refund': 'Đã hoàn tiền',
  'order.status_waiting': 'Chờ thanh toán',
  'order.status_success': 'Hoàn tất',
  'order.status_paid': 'Đã thanh toán',
  'order.status_reject': 'Đã hủy',
  'order.status_cancel': 'Đã hủy',
  'order.status_expired': 'Quá hạn',
  'order.content': 'Nội dung',
  'order.createdBy': 'Người tạo',
  'order.inCharge': 'Người phụ trách',
  'order.presenter': 'Người giới thiệu',
  'order.createdAt': 'Ngày tạo',
  'order.costType': 'Loại chi phí',
  'order.note': 'Ghi chú',
  'order.departure_date': 'Ngày khởi hành',
  'order.payment_method_': '',
  'order.payment_method_ale-pay': 'Ngân lượng',
  'order.payment_method_cash': 'Tiền mặt',
  'order.payment_method_bank-transfer': 'Chuyển khoản',
  'order.payment_method_momo': 'Momo',
  'order.payment_method_vn-pay': 'Vnpay',
  'order.payment_method_zalo-pay': 'ZaloPay',
  'order.payment_method_ngan-luong': 'Ngân lượng',
  'order.payment_method_swipe-card': 'Quẹt thẻ',
  // Visa
  'visa.status_deposited': 'Đã đặt cọc',
  'visa.status_created': 'Tạo mới',
  'visa.status_success': 'Hoàn tất',
  'visa.status_cancel': 'Đã hủy',
  // quotation
  'quotation.add': 'Thêm báo giá',
  'quotation.id': 'Mã báo giá',
  'quotation.trip': 'Chuyến đi',
  'quotation.price': 'Số tiền báo giá',
  'quotation.select_partner': 'Chọn đối tác',
  'quotation.use_trip': 'Sử dụng trip',
  'quotation.adult_cost': 'Mức chi phí trên đầu người lớn',
  'quotation.child_cost': 'Mức chi phí trên đầu trẻ em',
  'quotation.bady_cost': 'Mức chi phí trên đầu sơ sinh',
  'quotation.total_cost': 'Tổng chi phí',
  'quotation.select_policy': 'Chọn chính sách',
  'quotation.search_hotel': 'Tìm kiếm khách sạn cho báo giá',
  'quotation.hotel': 'Khách sạn',
  'quotation.service_cost_per_person': 'Chi phí riêng dịch vụ theo đầu người',
  'quotation.vehicle': 'Phương tiện',
  'quotation.restaurant': 'Ăn uống',
  'quotation.ticket': 'Vé tham quan',
  'quotation.airportPickup': 'Đưa đón tại sân bay',
  'quotation.sim4g': 'Sim 4g',
  'quotation_status.sent': 'Đã gửi',
  'quotation_status.accepted': 'Đã chấp nhận báo giá',
  'quotation_status.rejected': 'Đã hủy',
  'quotation_status.invoiceSent': 'Đã gửi hóa đơn',
  'quotation_status.canceled': 'Đã hủy',
  'quotation_status.paid': 'Đã thanh toán',
  'quotation_status.partialPaid': 'Đã thanh toán một phần',
  'quotation_status.completed': 'Đã hoàn thành',
  'receipt_status.pending': 'Chờ duyệt',
  'receipt_status.approved': 'Đã duyệt',
  'receipt_status.canceled': 'Đã hủy',
  // blog
  'blog.category_experience': 'Kinh nghiệm du lịch',
  'blog.category_knowledge': 'Kiến thức',
  'blog.category_news': 'Tin tức',
  'blog.category_tips': 'Tip du lịch',
  // language
  'language.english': 'Tiếng anh',
  'language.vietnamese': 'Tiếng Việt',
  'language.chinese': 'Tiếng Trung',
  'language.korean': 'Tiếng Hàn',
  'language.japanese': 'Tiếng Nhật',
  'language.german': 'Tiếng Đức',
  'language.italian': 'Tiếng Ý',
  'language.french': 'Tiếng Pháp',
  'language.russian': 'Tiếng Nga',
  'language.portuguese': 'Tiếng Bồ Đào Nha',
  'language.spanish': 'Tiếng Tây Ba Nha',
  'language.lao': 'Tiếng Lào',
  'language.cambodian': 'Tiếng Campuchia',
  'language.indian': 'Tiếng Ấn Độ (Hindi)',
};
